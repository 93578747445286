<template>
	<el-scrollbar class="uu-box uu-assess">
		<div class="uu-assess-head">
			<div class="head-info">
				<span>{{$t('seller.report.realName')}}{{assessInfo.real_name}}</span>
				<span>{{$t('seller.report.userName')}}{{assessInfo.user_name}}</span>
				<span>{{$t('seller.report.shopName')}}{{assessInfo.store_name}}</span>
			</div>
			<table class="uu-assess-table">
				<tr>
					<th></th>
					<th>{{$t('seller.tableHead.grade')}}</th>
					<th>{{$t('seller.tableHead.ranking')}}</th>
				</tr>
				<tr>
					<td>{{$t('seller.report.system')}}</td>
					<td>{{assessInfo.system_score}}</td>
					<td>{{assessInfo.intelligent_score_ranking}}</td>
				</tr>
				<tr>
					<td>{{$t('seller.report.teacher')}}</td>
					<td>{{assessInfo.teacher_rating}}</td>
					<td>{{assessInfo.teacher_rating_ranking}}</td>
				</tr>
				<tr>
					<td>{{$t('seller.report.total')}}</td>
					<td>{{assessInfo.total_score}}</td>
					<td>{{assessInfo.total_score_ranking}}</td>
				</tr>
			</table>
		</div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane :label="$t('seller.report.paneName1')" name="system">
				<div class="t-page-system">
					<div class="item" v-for="(score,index) in systemItems" :key="index">
						<div class="item-head">{{score.name}}</div>
						<div class="score-table">
							<table width="100%">
								<thead>
									<tr>
										<th width="200px"><div class="table-item">{{$t('seller.tableHead.title')}}</div></th>
										<th width="200px"><div class="table-item">{{$t('seller.tableHead.score')}}</div></th>
										<th width="400px">
											<el-row>
												<el-col :span="12"><div class="table-item">{{$t('seller.tableHead.scoreItem')}}</div></el-col>
												<el-col :span="12"><div class="table-item">{{$t('seller.tableHead.scoreIntelligent')}}</div></el-col>
											</el-row>
										</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item,index2) in score.lists" :key="index2" :class="'striped'+item.score_config_item.length">
										<td><div class="table-item">{{item.name}}</div></td>
										<td><div class="table-item">{{item.ini_score}}</div></td>
										<td>
											<el-row v-for="(child,index3) in item.score_config_item" :key="index2+'_'+index3">
												<el-col :span="12"><div class="table-item">{{child.score_a}}</div></el-col>
												<el-col :span="12"><div class="table-item">{{child.score_b}}</div></el-col>
											</el-row>
										</td>
										<td></td>
									</tr>
									<!-- <template v-for="(item,index2) in score.lists">
										<tr :key="index2">
											<td :rowspan="item.score_config_item.length+1">{{item.name}}</td>
											<td :rowspan="item.score_config_item.length+1" align="center">{{item.ini_score}}</td>
										</tr>
										<tr v-for="(child,index3) in item.score_config_item" :key="index2+'_'+index3">
											<td align="center">{{child.score_a}}</td>
											<td align="center">{{child.score_b}}</td>
											<td></td>
										</tr>
									</template> -->
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane :label="$t('seller.report.paneName2')" name="teacher">
				<div class="t-page-teacher">
					<table width="100%">
						<tr>
							<th>{{$t('seller.tableHead.total')}}</th>
							<th>{{$t('seller.tableHead.totalScore')}}</th>
							<th>{{$t('seller.tableHead.scoreTeacher')}}</th>
							<th>{{$t('seller.tableHead.comment')}}</th>
							<th></th>
						</tr>
						<tr>
							<td width="15%" align="center"></td>
							<td width="20%" align="center">{{assessInfo.total_score}}</td>
							<td width="15%" align="center">{{assessInfo.teacher_rating}}</td>
							<td width="20%" align="center"></td>
							<td></td>
						</tr>
						<tr v-for="(item,index) in teacherItems" :key="index">
							<td width="15%"><a href="javascript:void(0);">{{item.name}}</a></td>
							<td width="20%">{{item.remark}}</td>
							<td width="15%">
								<el-rate :value ="item.score/0.6" disabled></el-rate>
								<div class="star-tip">{{$t('seller.report.scoreText')}}{{item.score}}</div>
							</td>
							<td width="20%">
								<el-input v-model="item.comment" :disabled="true" maxlength="250" size="small" :placeholder="$t('seller.placeholder.instruction')"></el-input>
							</td>
							<td></td>
						</tr>
					</table>
				</div>
			</el-tab-pane>
		</el-tabs>
	</el-scrollbar>
</template>

<script>
	import { fetchScore, scoreInfo } from '@/api/seller/assess'
	export default {
		data() {
			return {
				activeName: 'system',
				assessInfo: {},
				systemItems: [],
				teacherItems: [],
			}
		},
		mounted(){
			this.getAssessInfo();
			this.getSystemItems();
			this.getTeacherItems();
		},
		methods: {
			getAssessInfo(){
				fetchScore({ uuid: this.$store.getters.uuid }).then(response => {
					this.assessInfo = response.data
				})
			},
			getSystemItems(){
				scoreInfo({ type: 2 }).then(response => {
					this.systemItems = response.data.list
				})
			},
			getTeacherItems(){
				scoreInfo({ type: 1 }).then(response => {
					this.teacherItems = response.data.list
				})
			},
			handleClick(tab, event){
			},
		}
	}
</script>

<style lang="scss" scoped>
	.uu-assess {
		.uu-assess-head {
			padding: 20px;
			.head-info {
				margin-bottom: 20px;
				span {
					font-size: 14px;
					margin-right: 20px;
				}
			}
		}
		.uu-assess-table {
			width: 500px;
			text-align: center;
			border: 1px solid #ffffff;
			th,td {
				padding: 8px 10px;
				line-height: 16px;
				border: 1px solid #ffffff;
			}
			tr:nth-child(2n) {
				td {
					background-color: $--seller-thead-background-2;
				}
			}
			th {
				color: #ffffff;
				background-color: $--seller-primary;
			}
		}
		::v-deep .el-tabs {
			.el-tabs__header {
				margin-bottom: 0;
				.el-tabs__nav-wrap {
					padding: 0 24px;
				}
				.el-tabs__nav-wrap::after {
					height: 1px;
				}
			}
		}
		.t-page-system {
			padding: 20px;
			.item {
				margin-bottom: 20px;
				.item-head {
					font-size: 16px;
					line-height: 24px;
					padding: 10px;
					font-weight: bold;
				}
			}
			.score-table {
				table {
					border-collapse: collapse;
					th,td {
						border: 1px solid #f0f2f4;
					}
					th {
						color: #ffffff;
						background-color: $--seller-primary;
						&:last-child {
							background-color: transparent;
						}
					}
					.table-item {
						line-height: 20px;
						padding: 10px;
					}
					
					::v-deep .el-row {
						border-bottom: 1px solid #f0f2f4;
						.el-col:nth-child(2n) {
							border-left: 1px solid #f0f2f4;
						}
						&:last-child {
							border-bottom: none;
						}
					}
					td {
						.el-row:nth-child(2n) {
							background: $--seller-thead-background-2;
						}
					}
					tr:nth-child(2n) {
						&.striped1 {
							.el-row:nth-child(2n-1) {
								background: $--seller-thead-background-2;
							}
						}
					}
				}
			}
		}
		.t-page-teacher {
			padding: 20px;
			table {
				border-collapse: collapse;
				th,td {
					padding: 10px;
					line-height: 1.5;
					border: 1px solid $--seller-thead-background-1;
				}
				th {
					background-color: $--seller-thead-background-1;
				}
			}
			.star-tip {
				color: #f56c6c;
				margin-top: 6px;
			}
		}
	}
</style>
